<template>
    <div class="row tabulator">
        <div class="col-xxl-4 col-xl-5">
            <TabulatorLink v-for="({title, description}, index) in items" :key="index" :title="title" :description="description" :is-active="activeTab === index" @click="activeTab = index"></TabulatorLink>
        </div>
        <div class="col-xxl-8 col-xl-7">
            <img src="../assets/lime_element.png" class="tabulator__lime" width="386" height="383" alt="" />
            <TabulatorContent v-for="({items}, index) in items" :key="index" :items="items" :is-active="activeTab === index"></TabulatorContent>
        </div>
    </div>
</template>

<script>
    import TabulatorLink from "./TabulatorLink";
    import TabulatorContent from "./TabulatorContent";
    export default {
        name: "Tabulator",
        components: {TabulatorContent, TabulatorLink},
        props: ['items'],
        data() {
            let data = {
                activeTab: 0
            };
            return data;
        },
    }
</script>

<style lang="scss">
    .tabulator {
        &:hover .tabulator__lime {
            animation-name: breathe;
            animation-duration: 6s;
            transition: .1s all ease-in-out;
            animation-iteration-count: infinite;
        }
    }
    .tabulator__lime {
        position: absolute;
        right: -4rem;
        bottom: -1rem;
    }

    @keyframes breathe {
        10% {transform: scale(1); filter: hue-rotate(0deg);}
        30% {  transform: scale(1.025); filter: hue-rotate(90deg);}
        60% {  transform: scale(0.975); filter: hue-rotate(180deg);}
        90% {  transform: scale(1.015); filter: hue-rotate(270deg);}
        100% {  transform: scale(1); filter: hue-rotate(360deg);}
    }
</style>
