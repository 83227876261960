<template>
        <Slider></Slider>
        <section class="container-fluid pl pr">
            <div class="row">
                <div class="col-xxl-9 mx-auto  py-4 mt-5 position-relative">
                    <Tabulator :items="items"></Tabulator>
                </div>
            </div>
        </section>
        <section class="container-fluid pl pr">
            <div class="row">
                <div class="col-xxl-9 mx-auto py-4 mt-5 text-center position-relative">
                    <div class="title-3 mb-4 lh-1" data-aos="fade-down"  data-aos-delay="50">Сделайте шаг к эффективному<br>рабочему инструменту прямо сейчас!</div>
                    <div class="title-4 mb-4" data-aos="fade-down"  data-aos-delay="50">Вам нужно только заполнить бриф</div>
                    <ButtonLime class="mb-5" data-aos="fade-up"  data-aos-delay="50">Заполнить бриф</ButtonLime>
                </div>
            </div>
        </section>
</template>

<script>

    import Slider from "../components/Slider";
    import ButtonLime from "../components/ButtonLime";
    import Tabulator from "../components/Tabulator";
    export default {
        name: 'Development',
        components: {
            Tabulator,
            ButtonLime,
            Slider
        },
        data(){
            return {
                items: [
                    {
                        title: 'Сайт',
                        description: '4 этапа работы над сайтом, который окупится за 3 месяца',
                        items:[
                            {
                                url:'./icons/strategy.png',
                                title: 'Стратегия',
                                description: 'Изучение ниши, подбор концепции, обсуждение идей',
                            },
                            {
                                url:'./icons/design.png',
                                title: 'Дизайн',
                                description: 'Создание прототипа, написание текста, разработка UI/UX  дизайна',
                            },
                            {
                                url:'./icons/programming.png',
                                title: 'Программирование',
                                description: 'Верстка, интеграция дополнительных сервисов, тестирование, запуск',
                            },
                            {
                                url:'./icons/support.png',
                                title: 'Поддержка',
                                description: 'Техническая поддержка, защита от вирусов, обеспечение безопасности',
                            }
                        ]
                    },
                    {
                        title: 'Програмное обеспечение',
                        description: '4 этапа работы над качественным программным обеспечением',
                        items:[
                            {
                                url:'./icons/list.png',
                                title: 'Проект',
                                description: 'Анализ задачи, маркетинговое исследование, составление ТЗ',
                            },
                            {
                                url:'./icons/prototype.png',
                                title: 'Прототип',
                                description: 'Составление сценариев взаимодействия, дизайн шаблонов',
                            },
                            {
                                url:'./icons/programming.png',
                                title: 'Программирование',
                                description: 'Написание кода, доработка функционала',
                            },
                            {
                                url:'./icons/test.png',
                                title: 'Тестирование',
                                description: 'Устранение недостатков, отработка пользовательских сценариев',
                            }
                        ]
                    }
                ],
            }
        }
    }
</script>
