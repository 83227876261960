<template>
    <div class="tabulator__link" :class="{'tabulator__link--active':isActive}">
        <span v-text="title" class="tabulator__link__title title-3 lh-1"></span>
        <transition name="fade">
            <span v-if="isActive" v-text="description" class="tabulator__link__description lh-1"></span>
        </transition>
    </div>
</template>

<script>
    export default {
        name: "TabulatorLink",
        props:{
            title: String,
            description: String,
            isActive: Boolean
        }
    }
</script>

<style lang="scss">
    .tabulator__link {
        display: block;
        cursor: pointer;
        opacity: .8;
        margin: 3rem 0;
        transition: .2s all ease-in-out;

        @media (max-width: 768px) {
            margin: 1rem 0;
        }

        &--active, &:hover {
            opacity: 1;
        }

        &__title {
            display: inherit;
            margin-bottom: 0.5rem;
            transition: .3s all ease-in-out;
        }

        &__description {
            font-size: 1.75rem;
            font-style: italic;
            @media (max-width: 768px) {
                font-size: 1.25rem;
            }
        }
    }
</style>
