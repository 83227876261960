<template>
        <section class="container-fluid py-5 pl pr bg-lc-grey">
            <div class="row">
                <div class="col-12 py-4 mt-5">
                    <h2 class="title-3 text-center mb-4" data-aos="fade-down"  data-aos-delay="50">4 ГЛАВНЫХ принципа LimeClub</h2>
                    <div class="row" data-aos="fade-up"  data-aos-delay="50">
                        <div class="mains col-lg-3  col-md-6 order-3">
                            <div class="mains__content">
                                <img src="../assets/box.png" width="62" height="57" class="mb-4"/>
                                <p class="fw-bolder">Комплексный подход.</p>
                                <p>Предоставляем комплекс услуг в рамках одного проекта.</p>
                            </div>
                        </div>
                        <div class="mains col-lg-3  col-md-6 order-2">
                            <div class="mains__content">
                                <img src="../assets/diagram.png" width="65" height="65" class="mb-4"/>
                                <p class="fw-bolder">Развитие.</p>
                                <p>Изучаем новые техники и направления работы.</p>
                            </div>
                        </div>
                        <div class="mains col-lg-3  col-md-6 order-1">
                            <div class="mains__content">
                                <img src="../assets/clock.png" width="63" height="63" class="mb-4"/>
                                <p class="fw-bolder">Соблюдение сроков.</p>
                                <p>Ценим Ваше время, сдавая проект вовремя.</p>
                            </div>
                        </div>
                        <div class="mains col-lg-3  col-md-6 order-0">
                            <div class="mains__content">
                                <img src="../assets/smile.png" width="65" height="66" class="mb-4"/>
                                <p class="fw-bolder">Ответственность.</p>
                                <p>Контролируем качество выполняемой работы на каждом этапе.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xxl-8 mx-auto py-4 mt-5 text-center position-relative">
                    <h3 class="title-5 mb-5" data-aos="fade-down"  data-aos-delay="150">Оставьте свой номер и менеджер LimeClub свяжется с Вами:</h3>
                    <ButtonLime data-aos="fade-up"  data-aos-delay="150">Получить консультацию</ButtonLime>
                </div>
            </div>
        </section>
</template>

<script>
    import ButtonLime from "../components/ButtonLime";
    export default {
        name: "Contacts",
        components: {ButtonLime}
    }
</script>

<style lang="scss" scoped>
    section {
       min-height: calc(100vh - 72px);
    }

        .mains {
            position:relative;
            .mains__content {
                position: relative;
                z-index: 1;
                background: var(--lc-grey);
                padding: 2.5rem 1.5rem 2.5rem 3rem ;
                height: 100%;
                img {
                    height:65px;
                    width: auto;
                }
                p {
                    position: relative;
                    z-index: 1;
                }
                &:before{
                    content: '';
                    position: absolute;
                    font-size: 9.5rem;
                    font-weight: 100;
                    z-index: 0;
                    color:var(--bs-white);
                    left:0;
                    bottom: 0;
                }

            }
            &:nth-of-type(4) .mains__content:before{
                content: '1';
            }
            &:nth-of-type(3) .mains__content:before{
                content: '2';
            }
            &:nth-of-type(2) .mains__content:before{
                content: '3';
            }
            &:nth-of-type(1) .mains__content:before{
                content: '4';
            }

            &:before{
                content: '';
                position: absolute;
                height: 80%;
                width: 50%;
                top: 50%;
                right: 1rem;
                border-radius: 10rem;
                box-shadow: 0 0 25px var(--bs-dark);
                transform: translateY(-50%);
                z-index: 0;
            }
        }
</style>
