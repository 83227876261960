<template>
    <kinesis-container>
        <section class="container-fluid py-5 pl pr vh-100 overflow-hidden marketing-container">
            <div class="row py-xxl-5 py-lg-4 py-3 h-100">
                <div class="col-lg-7 h-100 ps-5 pe-0 d-flex flex-column justify-content-center"   data-aos="fade-right" data-aos-delay="50">
                    <div class="position-relative">
                        <h1 class="title-1 lh-1 text-center mb-5">Marketing</h1>
                        <kinesis-element :strength="-10">
                            <img src="../assets/lupa.png" class="position-absolute bottom-0 start-50"/>
                        </kinesis-element>

                    </div>
                    <div class="text-center position-relative">
                        <div  class="position-absolute" style="bottom: 10%!important;left:25%; width: 15%">
                            <kinesis-element :strength="-50" class="w-100">
                                <img src="../assets/lime.png" class="w-100"/>
                            </kinesis-element>
                        </div>
                        <kinesis-element :strength="30">
                            <img src="../assets/ipad.png" class="position-relative" style="width: 60%"/>
                        </kinesis-element>
                    </div>
                </div>
                <div class="col-lg-5 h-100 d-flex flex-column justify-content-center position-relative">
                    <div>
                        <p class="fs-2 sub-title fw-bolder  mb-xl-5 pb-xl-5 mb-3 pb-3"  data-aos="fade-left" data-aos-delay="50">Маркетинг - секрет успеха современного бизнеса</p>
                        <p class="fs-5 sub-title mb-5" data-aos="fade-left" data-aos-delay="150">Результат работы команды маркетологов LimeClub:</p>
                        <ul class="fs-5  mb-xl-5 pb-xl-5 mb-3 pb-3">
                            <li class="mb-xxl-2" data-aos="fade-left" data-aos-delay="250">В 5 раз больше заявок</li>
                            <li class="mb-xxl-2" data-aos="fade-left" data-aos-delay="350">На 75% рост доверия к бренду</li>
                            <li class="mb-xxl-2" data-aos="fade-left" data-aos-delay="450">В два раза снижение расходов на рекламу</li>
                        </ul>
                        <ButtonLime data-aos="fade-up"  data-aos-delay="650" data-aos-anchor-placement="center-bottom">Узнать стоимость</ButtonLime>
                    </div>

                </div>

            </div>
        </section>

    </kinesis-container>

    <section class="container-fluid pl pr marketing-tabulator-container">
        <div class="row">
            <div class="col-xxl-9 mx-auto  py-4 mt-5 position-relative">
                <Tabulator :items="items"></Tabulator>
            </div>
        </div>
    </section>
    <section class="container-fluid pl pr">
        <div class="row">
            <div class="col-xxl-9 mx-auto py-4 mt-5 text-center position-relative">
                <div class="title-4 mb-4" data-aos="fade-down"  data-aos-delay="50">Заполните бриф</div>
                <div class="title-3 mb-4 lh-1" data-aos="fade-down"  data-aos-delay="50">и мы подберем услугу, которая<br>необходима именно Вашему бизнесу</div>

                <ButtonLime class="mb-5" data-aos="fade-up"  data-aos-delay="50">Заполнить бриф</ButtonLime>
            </div>
        </div>
    </section>
</template>

<script>
    import ButtonLime from "../components/ButtonLime";
    import Tabulator from "../components/Tabulator";
    export default {
        name: 'Marketing',
        components: {
            Tabulator,
            ButtonLime
        },
        data(){
            return {
                items: [
                    {
                        title: 'Маркетинговая стратегия',
                        description: '',
                        items:[
                            {
                                url:'./icons/strategy.png',
                                title: '',
                                description: 'Изучение внутренних процессов компании',
                            },
                            {
                                url:'./icons/design.png',
                                title: '',
                                description: 'Составление целей маркетинга',
                            },
                            {
                                url:'./icons/programming.png',
                                title: '',
                                description: 'Разработка путей достижения целей',
                            },
                            {
                                url:'./icons/support.png',
                                title: '',
                                description: 'Презентация продуманной стратегии и ее преимуществ',
                            },
                            {
                                url:'./icons/support.png',
                                title: '',
                                description: 'Внедрение стратегии и отслеживание ее эффективности',
                            }
                        ]
                    },
                    {
                        title: 'Маркетинг на аутсорсинге',
                        description: '',
                        items:[
                            {
                                url:'./icons/list.png',
                                title: '',
                                description: 'Проведение аудита компании, включающий в себя анализ рыка и конкурентов, сегментация целевой аудиттории',
                            },
                            {
                                url:'./icons/prototype.png',
                                title: '',
                                description: 'Определение и описание потреббностей бизнеса',
                            },
                            {
                                url:'./icons/programming.png',
                                title: '',
                                description: 'Разработка стратегии работы в зависимости от целей маркетинга',
                            },
                            {
                                url:'./icons/test.png',
                                title: '',
                                description: 'Подбор интсрументов для работы с целями',
                            },
                            {
                                url:'./icons/test.png',
                                title: '',
                                description: 'Запуск продуманной стратегии и контроль за результатами',
                            }
                        ]
                    },
                    {
                        title: 'PROдвижение',
                        description: '',
                        items:[
                            {
                                url:'./icons/list.png',
                                title: '',
                                description: 'Изучение специфики бизнеса и его преимуществ',
                            },
                            {
                                url:'./icons/prototype.png',
                                title: '',
                                description: 'Подбор комлекса эффективных маркетинговых инструментов',
                            },
                            {
                                url:'./icons/programming.png',
                                title: '',
                                description: 'Составление плана по достижению целей',
                            },
                            {
                                url:'./icons/test.png',
                                title: '',
                                description: 'Реализация запланированных мероприятий, при необходимости привлекая проверенных подрядчиков',
                            },
                            {
                                url:'./icons/test.png',
                                title: '',
                                description: 'Анализ результатов, коррекция индивидуальных деталей плана',
                            }
                        ]
                    },
                    {
                        title: 'Маркетинговый анализ',
                        description: '',
                        items:[
                            {
                                url:'./icons/list.png',
                                title: '',
                                description: 'Постановка целей и составление индивидуальной программы анализа',
                            },
                            {
                                url:'./icons/prototype.png',
                                title: '',
                                description: 'Проведение внутренних и внешних исследований бизнеса',
                            },
                            {
                                url:'./icons/programming.png',
                                title: '',
                                description: 'Изучение рынка и наиболее сильных конкурентов',
                            },
                            {
                                url:'./icons/test.png',
                                title: '',
                                description: 'Составление отчета о положении компании',
                            },
                            {
                                url:'./icons/test.png',
                                title: '',
                                description: 'Предоставление вариантов дальнейшего развития компании',
                            }
                        ]
                    }
                ],
            }
        }
    }
</script>
<style lang="scss">
    .marketing-container {

        h1 {
            font-size:10vw;
        }
        &:before {
            content: '';
            position: absolute;
            height: 100vh;
            width: 100vw;
            background: var(--lc-grey);
            top: -10%;
            left: -40%;
        }
    }
    .marketing-tabulator-container {
        .tabulator__content__item {
            height:12rem!important;
        }
    }
</style>
