<template>
    <div v-if="isActive">
        <TabulatorContentItem v-for="({url,title,description},index) in items" :key="index" :index="index +1" :url="url" :title="title" :description="description"></TabulatorContentItem>
    </div>
</template>

<script>
    import TabulatorContentItem from "./TabulatorContentItem";
    export default {
        name: "TabulatorContent",
        props:{
            items: Array,
            isActive: Boolean
        },
        components: {TabulatorContentItem}
    }
</script>

<style scoped>

</style>
