<template>
  <div class="slider d-flex flex-row vh-100 overflow-hidden ">
    <div class="slider-slide slider-slide--left min-vw-100 h-100 d-flex align-items-end position-relative" :class="sliderSlideLeftClass">
      <div class="row w-100 h-100 gx-0">

        <div class="col" :class="active === 'none' ? 'col-6' : 'col-8'">

          <div class="row">
            <div class="col pl">
              <div class="fs-4 sub-title sub-title--compact">Landing page</div>
              <div class="sub-content">уникальное одностраничное предложение проведет Ваших клиентов по воронке продаж к целевому действию</div>
            </div>
            <div class="col pl">
              <div class="fs-4 sub-title sub-title--compact">Корпоративный сайт</div>
              <div class="sub-content">индивидуальный многостраничный сайт поможет заявить о компании в Интернете и наладить коммуникации с потребителями</div>
            </div>
          </div>

          <div class="row">
            <div class="col pl">
              <div class="fs-4 sub-title sub-title--compact">Интернет-магазин</div>
              <div class="sub-content">лучший on-line магазин в своем сегменте поможет увеличить объемы оптовых и розничных продаж</div>
            </div>
            <div class="col pl">
              <div class="fs-4 sub-title sub-title--compact">Портал</div>
              <div class="sub-content">многофункциональный web-ресурс реализует индивидуальные задачи бизнеса</div>
            </div>
          </div>

        </div>

        <div class="col col-title pl  position-relative" :class="active === 'none' ? 'col-6' : 'col-4'">
          <div class="title-1 lh-1 mb-3" data-aos="fade-down"  data-aos-delay="50">Sites</div>
          <transition name="fade">
            <div class="fs-4 sub-title sub-title--compact" v-show="active !== 'left'">Комплексные Web-решения<br>Ускорят развитие Вашего бизнеса</div>
          </transition>
          <div class="arrow-more text-uppercase position-absolute bottom-0 mb-3 pl pr"  :class="sliderSlideLeftArrowMoreClass" @mousedown="active = active != 'left' ? 'left' : 'none'">
            <template v-if="active ==='left'">
              Вернуться<img class="arrow-more__img--right" src="../assets/arrow.svg" />
            </template>
            <template v-else>
              <img class="arrow-more__img--left" src="../assets/arrow.svg" />Подробнее
            </template>
          </div>
        </div>

      </div>
    </div>
    <div class="slider-slide slider-slide--right min-vw-100 h-100 d-flex align-items-end" >
      <div class="row w-100 h-100 gx-0" :class="active === 'right' ? 'pe-5' : false">

        <div class="col col-title pl position-relative" :class="active === 'none' ? 'col-6' : 'col-4'">
          <div class="title-1 lh-1 mb-3" data-aos="fade-down"  data-aos-delay="50">Soft</div>
          <transition name="fade">
            <div class="fs-4 sub-title sub-title--compact" v-show="active !== 'right'" >Уникальное програмное обеспечение<br>Позволят оптимизировать бизнес-процессы</div>
          </transition>
          <div class="arrow-more text-uppercase position-absolute bottom-0 mb-3 pl pr" :class="sliderSlideRightArrowMoreClass" @mousedown="active = active != 'right' ? 'right' : 'none'">
            <template v-if="active ==='right'">
              <img class="arrow-more__img--left" src="../assets/arrow.svg" />Вернуться
            </template>
            <template v-else>
              Подробнее<img class="arrow-more__img--right" src="../assets/arrow.svg" />
            </template>
          </div>
        </div>

        <div class="col" :class="active === 'none' ? 'col-6' : 'col-8'">

          <div class="row">

            <div class="col pl">
              <div class="fs-4 sub-title sub-title--compact">Система электронного документооборота</div>
              <div class="sub-content">автоматизация бизнес-процессов документооборота как внутри компании, так и с внешними поставщиками</div>
            </div>
            <div class="col pl">
              <div class="fs-4 sub-title sub-title--compact">Мобильные приложения</div>
              <div class="sub-content">создание уникальных приложений любой сложности</div>
            </div>
          </div>

          <div class="row">
            <div class="col pl">
              <div class="fs-4 sub-title sub-title--compact">Система дистанционного обучения</div>
              <div class="sub-content">возможность обучения Ваших студентов из любой точки мира в режиме 24/7</div>
            </div>
            <div class="col pl">
              <div class="fs-4 sub-title sub-title--compact">Внутреннее программное обеспечение </div>
              <div class="sub-content">разработка и внедрение систем различной сложности поможет решить уникальные задачи бизнеса</div>
            </div>
          </div>
        </div>

      </div>

    </div>
    <div class="flyImg position-absolute pl pr" :class="sliderFlyImgClass">

      <transition name="fade">
        <img v-show="active !== 'right'" class="keyboard position-absolute" src="../assets/klava.png"/>
      </transition>

      <img v-show="showMouse"  class="mouse position-absolute" src="../assets/mouse.png" />

      <div class="computer overflow-hidden position-relative"  data-aos="zoom-in"  data-aos-delay="50">
        <img class="computer__self position-relative" src="../assets/monoblock.png"/>
        <transition name="fade">
          <img v-show="active === 'left'" class="computer__display computer__display--left position-absolute" src="../assets/left_monitor.png"/>
        </transition>
        <transition name="fade">
          <img v-show="active === 'right'" class="computer__display computer__display--right position-absolute" src="../assets/right_monitor.png"/>
        </transition>
        <transition name="fade">
          <img v-show="active === 'none'" class="computer__display computer__display--middle position-absolute" src="../assets/blocchain.png"/>
        </transition>
      </div>

      <img class="lime position-absolute" src="../assets/lime.png"/>
    </div>
  </div>
</template>

<script>
  export default {
    name: "Slider",
    data() {
      let data = {
        active: "none",
        showMouse: true
      };
      return data;
    },
    computed: {
      sliderSlideLeftClass() {
        return this.active === "left"
                ? "slider-slide--left--active"
                : this.active === "right"
                        ? "slider-slide--left--no-active"
                        : false;
      },
      sliderSlideLeftArrowMoreClass() {
        return this.active === "left"
                ? "arrow-more--left"
                : "arrow-more--right";
      },
      sliderSlideRightArrowMoreClass() {
        return this.active === "right"
                ? "arrow-more--right"
                : "arrow-more--left";
      },
      sliderFlyImgClass() {
        return this.active === "left"
                ? "flyImg--left"
                : this.active === "right"
                        ? "flyImg--right"
                        : "flyImg--none";
      }
    },
    watch: {
      active: function(val) {
        if(val === 'left') {
          this.showMouse = false;
        } else {
          let component = this;
          setTimeout(() => {
            component.showMouse = true;
          }, 500)
        }
      }
    }
  };
</script>

<style lang="scss">

  .slider {
    padding-bottom: 5rem;

    @media screen and (max-height: 768px) {
      padding-bottom: 2rem;
    }
    .flyImg {
      bottom: 8rem;
      transition: .5s all ease-in-out;

      .computer {
        .computer__self {
          height:50vh;

          @media screen and (max-height: 768px) {
            height:40vh;
          }
        }
        &__display {
          top: 5%;
          height: 61%;

          &--middle {
            left: -55%;
          }
          &--left, &--right {
            left: 6%;
          }
        }
      }



      .keyboard {
        height:10vh;
        left: -40%;
        bottom: 20%;
        transition:.4s all  ease-in-out;
        @media screen and (max-height: 768px) {
          height:8vh;
        }
      }

      .mouse {
        height:8vh;
        bottom: 10%;
        right:-20%;
        transition:.5s all  ease-in-out;
        @media screen and (max-height: 768px) {
          height:6.5vh;
        }
      }

      .lime {
        height:10vh;
        bottom: -5%;
        right: 10%;
        transition:.3s all  ease-in-out;
        @media screen and (max-height: 768px) {
          height:8vh;
        }
      }

      &--none {
        left: 50%;
        transform: translateX(-50%);
      }

      &--left {
        left: 100%;
        transform: translateX(-100%);
        .keyboard {
          left: -50%;
          bottom: 5%;
        }
        .lime {
          right: 25%;
        }
      }

      &--right {
        left: 0%;
        transform: translateX(0%);
        .mouse {
          right: -55%;
        }
        .lime {
          right: -15%;
        }
      }





    }

    .slider-slide{

      padding-top: 9rem;

      @media screen and (max-height: 768px) {
        padding-top: 6rem;
      }
      .row {
        z-index: 1;

        .col {
          transition: 0.3s all ease-in-out;

          &.col-title {
            .sub-title {
              white-space: nowrap;
            }
          }
        }

        .arrow-more {

          transition: .75s all cubic-bezier(.42, 0, .58, 1);

          &--left {
            left:100%;
            transform: translateX(-100%);
          }

          &--right {
            left:0;
          }

        }

      }

      &--left {
        margin-left: -50%;
        transition: 0.5s all ease-in-out;

        &:before {
          content: '';
          display: block;
          height: 100%;
          width: 100%;
          background: var(--lc-grey);
          position: absolute;
          z-index: 0;
          transition: .5s all ease-in-out;
        }

        &--active {
          margin-left: 0%;

          &:before {
            width: 70%;
            left:0;
          }
        }

        &--no-active {
          margin-left: -100%;
        }

      }

    }
  }





</style>
