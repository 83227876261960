<template>
    <section class="container-fluid py-5 pl pr vh-100 overflow-hidden">
      <div class="row py-xxl-5 py-lg-4 py-3 h-100">
        <div class="col-xl-4 col-lg-5 h-100 d-flex flex-column justify-content-between">
          <div>
            <h1 class="title-2 lh-1"  data-aos="fade-right"  data-aos-delay="50">О нас</h1>
            <p class="fs-4 sub-title" data-aos="fade-right"  data-aos-delay="150">Используя опыт работы в дизайне, маркетинге и IT-сфере мы помогаем нашим заказчикам:</p>
            <ul class="fs-5 list-unstyled">
              <li class="mb-xxl-2" data-aos="fade-right"  data-aos-delay="250">Создавать и развивать свой бизнес</li>
              <li class="mb-xxl-2" data-aos="fade-right"  data-aos-delay="350">Повышать продажи</li>
              <li class="mb-xxl-2" data-aos="fade-right"  data-aos-delay="450">Выходить на новые рынки</li>
            </ul>
          </div>
          <div class="d-flex justify-content-end">
            <div class="arrow-more d-inline" id="swiper-button-prev">
              <img class="arrow-more__img--left" src="../assets/arrow.svg" />
            </div>
            <div class="arrow-more d-inline" id="swiper-button-next">
              <img class="arrow-more__img--right" src="../assets/arrow.svg" />
            </div>
          </div>
        </div>
        <div class="col-xl-8 col-lg-7 h-100 ps-5 pe-0" data-aos="fade-left"  data-aos-delay="50">
          <swiper v-bind="swiperOption" class="my-swiper h-100">
            <swiper-slide v-for="({url, title, description}, index) in slides" :key="index" class="position-relative h-100 w-100">
              <div class="slide-image" :style="{backgroundImage: 'url('+url+')'}"></div>
              <div class="slide-text w-lg-50 w-75 position-absolute p-4 top-50 end-0 bg-white">
                <p class="text-uppercase fw-bold mb-1" v-text="title"></p>
                <p v-text="description"></p>
              </div>
            </swiper-slide>
            <div class="swiper-pagination"></div>
          </swiper>
        </div>
      </div>
    </section>

    <section class="container-xxl py-5 pl pr">
      <div class="row justify-content-between">

        <div class="col-xxl-7 col-lg-8 position-relative">
          <img src="../assets/girl.png" data-aos="fade-right"  data-aos-delay="250" width="360" height="547" class="img-fluid position-absolute top-0 start-0 d-lg-block d-none mt-5" />
          <h3 data-aos="fade-right" class="title-3 text-lg-end text-center fw-bolder position-relative mb-lg-0 mb-5">Экспертный подход <br class="d-lg-block d-none"/>к каждому проекту:</h3>

        </div>

        <div class="col-xxl-5 col-lg-4 pb-4"  data-aos="fade-left"  data-aos-delay="50">
          <div class="row">
            <div class="col-3 text-lg-end text-center">
              <img src="../assets/notebook.png" width="89" height="58" alt="" class="img-fluid"/>
            </div>
            <div class="col-9">
              <p class="fw-bolder mb-3">UI/UX дизайн</p>
              <p>Создаем структуру сайта, которая поможет пользователям быстро находить необходимую информацию.</p>
            </div>
          </div>

        </div>
      </div>
      <div class="row">
        <div class="col-xxl-2 col-lg-4"></div>
        <div class="col-xxl-5 col-lg-4 pb-4"  data-aos="fade-left"  data-aos-delay="75">
          <div class="row">
            <div class="col-3 text-lg-end text-center">
              <img src="../assets/lamp.png" width="51" height="72" alt="" class="img-fluid"/>
            </div>
            <div class="col-9">
              <p class="fw-bolder mb-3">Поиск идеи</p>
              <p>Анализируем бизнес, изучаем продукт, формируем стратегию и разрабатываем яркую идею.</p>
            </div>
          </div>

        </div>
        <div class="col-xxl-5 col-lg-4 pb-4"  data-aos="fade-left"  data-aos-delay="125">
          <div class="row">
            <div class="col-3 text-lg-end text-center">
              <img src="../assets/jewel.png" width="78" height="70" alt="" class="img-fluid"/>
            </div>
            <div class="col-9">
              <p class="fw-bolder mb-3">Позиционирование бренда</p>
              <p>Продумываем характер бренда, разрабатываем айдентику с учетом наиболее эффективного сценария дальнейшего развития.</p>
            </div>
          </div>

        </div>
      </div>
      <div class="row pb-3">
        <div class="col-xxl-2 col-lg-4">

        </div>
        <div class="col-xxl-5 col-lg-4 pb-4"   data-aos="fade-left"  data-aos-delay="100">
          <div class="row">
            <div class="col-3 text-lg-end text-center">
              <img src="../assets/download.png" width="68" height="72" alt="" class="img-fluid"/>
            </div>
            <div class="col-9">
              <p class="fw-bolder mb-3">Использование технологий</p>
              <p>Тщательно следим за рынком и внедряем новейшие разработки, чтобы создать интересный проект.</p>
            </div>
          </div>

        </div>
        <div class="col-xxl-5 col-lg-4 pb-4"  data-aos="fade-left"  data-aos-delay="150">
          <div class="row">
            <div class="col-3 text-lg-end text-center">
              <img src="../assets/stick.png" width="68" height="70" alt="" class="img-fluid"/>
            </div>
            <div class="col-9">
              <p class="fw-bolder mb-3">Продумывание анимации</p>
              <p>Индивидуально подбираем визуальные, а также графические эффекты для каждой задачи. </p>
            </div>
          </div>

        </div>
      </div>
    </section>

    <section class="container-fluid pl pr bg-lc-grey">
      <div class="row py-5 mt-5">
        <div class="col-xxl-8 mx-auto py-5 mt-5 text-center position-relative">
          <h3 class="title-3 mb-5"  data-aos="fade-down"  data-aos-delay="50">Закажите бесплатную оценку<br>Вашего проекта ПРЯМО СЕЙЧАС</h3>
          <img src="../assets/lime_with_leaf.png" width="221" height="184" alt=""  class="position-absolute bottom-0 end-0" data-aos="zoom-in-up"/>
          <ButtonLime class="mb-5" data-aos="fade-up"  data-aos-delay="75">Заказать оценку</ButtonLime>
        </div>
      </div>

    </section>
</template>
<script>
  import SwiperCore, { Navigation, Pagination, EffectFlip  } from 'swiper';

  import { Swiper, SwiperSlide } from 'swiper/vue';
  import ButtonLime from "../components/ButtonLime";

  SwiperCore.use([Navigation, Pagination, EffectFlip ]);

  export default {
    components: {
      ButtonLime,
      Swiper,
      SwiperSlide,
    },
    data: function () {
      return {
        slides:[
          {
            url: './img/profi.jpg',
            title: 'Профессионализм',
            description: 'Среди нас работают опытные проектировщики, дизайнеры, frontend и backend программисты, маркетологи, разработчики мобильных приложений, тестировщики и другие специалисты'
          },
          {
            url: './img/dover.jpg',
            title: 'Доверие',
            description: 'Такие понятия, как вовлеченность, партнерство и слаженность в работе – основа наших взаимоотношений и внутри компании, и с заказчиками'
          },
          {
            url: './img/quality.jpg',
            title: 'Качество',
            description: 'Детально изучая бизнес клиента, стремимся создать уникальный проект, который принесет ему прибыль и поможет развиваться'
          },
          {
            url: './img/nova.png',
            title: 'Новаторство',
            description: 'Каждый член команды LimeClub непрерывно учится, изучая новейшие технологии и применяя полученные навыки на практике'
          }
        ],
        swiperOption: {
          effect: 'flip',
          slidesPerView: 1,
          spaceBetween: 0,
          loop: true,
          navigation: {
            nextEl: '#swiper-button-next',
            prevEl: '#swiper-button-prev'
          },
          pagination: {
            el: '.swiper-pagination',
            clickable:true
          }
        },
      }
    },
    methods: {
    }
  };
</script>

<style lang="scss">

  .title-2 {
    @media screen and (min-height: 768px){
      margin-top: 2rem;
      margin-bottom: 8rem;
    }
    @media screen and (max-height: 768px) and (min-height: 630px){
      margin-top: 1rem;
      margin-bottom: 3rem;
    }
  }
  .sub-title{

    @media screen and (min-height: 768px){
      margin-bottom: 3rem;
    }
    @media screen and (max-height: 768px) and (min-height: 630px){
      margin-bottom: 2rem;
    }
  }

  .my-swiper {
    overflow: visible;
    &:before {
      content: '';
      position: absolute;
      height: 100vh;
      width: 100vw;
      background: var(--lc-grey);
      bottom: 0;
      left: 10%;
    }

    .slide-image {
      position: absolute;
      top:0;
      bottom: 0;
      left:0;
      right:10%;
      background-position: left center;
      background-size: auto 100%;
      background-repeat: no-repeat;

    }
    .slide-text {
      transform: translate(-1rem , -50%);
      height: 12rem;
      box-shadow: 12px 12px 24px #777;
      perspective: 50px;
    }

    .swiper-pagination {
      text-align: right;
      padding-right: 1rem;

      &-bullet {
        opacity: 1!important;
        background-color: var(--lc-violet);

        &-active {
          background-color: #d8f220;
          background-image: linear-gradient( -35deg , #d8f220, #74ea00);
        }
      }
    }

    &.swiper-container-flip {
      overflow: visible;
      .swiper-slide {
        pointer-events: none;
        backface-visibility: hidden;
        z-index: 1;
        .swiper-slide {
          pointer-events: none;
        }
      }
      .swiper-slide-active {
        &,
        & .swiper-slide-active {
          pointer-events: auto;
        }
      }
      .swiper-slide-shadow-top,
      .swiper-slide-shadow-bottom,
      .swiper-slide-shadow-left,
      .swiper-slide-shadow-right {
        z-index: 0;
        backface-visibility: hidden;
      }
    }
  }

</style>
