<template>
    <div class="tabulator__content__item" :data-aos="animation[index]">
        <div class="text-end">
            <span v-text="'0' + index" class="tabulator__content__item__index"></span>
            <img :src="url" :alt="title"/>
        </div>
        <div>
            <p v-if="title" class="fw-bold mb-2"  v-text="title"></p>
            <span v-text="description"></span>
        </div>
    </div>
</template>

<script>
    export default {
        name: "TabulatorContentItem",
        props:{
            index: String,
            url: String,
            title: String,
            description: String
        },
        data(){
          return {
              animation:[
                  'zoom-in-down',
                  'zoom-in-down',
                  'zoom-in-down',
                  'zoom-in-up',
                  'zoom-in-up',
                  'zoom-in-up',
              ]
          }
        },
        methods: {
            getRandomInt: function (max) {
                return Math.floor(Math.random() * max);
            }
        }
    }
</script>

<style lang="scss">
    .tabulator__content__item {
        position: relative;
        overflow: hidden;
        width: calc(50% - 2rem);
        float: left;
        background: var(--bs-white);
        border-radius: .5rem;
        padding: 1.5rem ;
        box-shadow: 0 5px 24px #bcbcbc;
        height: 15rem;
        margin-right: 2rem;
        margin-bottom: 2rem;

        @media (max-width:768px) {
            width: 100%;
            margin-right: 0;
            margin-bottom: 1rem;
        }

        &:nth-of-type(odd) {
            margin-top: 1rem;
            margin-bottom: 1rem;

            @media (max-width: 768px){
                margin-top: 0;
                margin-bottom: 1rem;
            }
        }
        &>div {
            height: 50%;
        }

        &__index {
            font-size: 9rem;
            font-weight: bold;
            position: absolute;
            line-height: 1;
            left: -1rem;
            top: -2rem;
            color: var(--lc-grey);
        }

    }
</style>
