<template>
    <kinesis-container>
    <section class="container-fluid py-5 pl pr vh-100 overflow-hidden design-container">
        <div class="row py-xxl-5 py-lg-4 py-3 h-100">
            <div class="col-lg-7 h-100 ps-5 pe-0 d-flex flex-column justify-content-center position-relative"  data-aos="fade-right" data-aos-delay="50">
                <h1 class="title-1 lh-1 text-center translate-middle-y pb-xxl-5 mb-xxl-5">Design</h1>
                <div class="position-absolute text-center w-100" style="bottom: 12%">
                    <div  class="position-absolute" style="bottom: 10%!important;left:25%; width: 20%">
                        <kinesis-element :strength="-10" axis="x" class="w-100">
                            <img src="../assets/lime.png" class="w-100"/>
                        </kinesis-element>
                    </div>
                    <kinesis-element :strength="50" axis="x">
                        <img src="../assets/mug.png" style="width: 60%"/>
                    </kinesis-element>
                </div>
            </div>
            <div class="col-lg-5 h-100 d-flex flex-column justify-content-center position-relative">
                <div>
                    <p class="fs-2 sub-title fw-bolder  mb-xl-5 pb-xl-5 mb-3 pb-3" data-aos="fade-left" data-aos-delay="50">Дизайн, в который влюбятся ваши клиенты</p>
                    <p class="fs-5 sub-title mb-5" data-aos="fade-left" data-aos-delay="150">С командой LimeClub вы получите:</p>
                    <ul class="fs-5  mb-xl-5 pb-xl-5 mb-3 pb-3">
                        <li class="mb-xxl-2" data-aos="fade-left" data-aos-delay="250">100% уникальный проект</li>
                        <li class="mb-xxl-2" data-aos="fade-left" data-aos-delay="350">На 40% вырастет узнаваемость бренда</li>
                        <li class="mb-xxl-2" data-aos="fade-left" data-aos-delay="450">До 5 концепций проекта на выбор</li>
                    </ul>
                    <ButtonLime data-aos="fade-up"  data-aos-delay="650" data-aos-anchor-placement="center-bottom">Узнать стоимость</ButtonLime>
                </div>

            </div>

        </div>
    </section>
    </kinesis-container>
        <section class="container-fluid pl pr">
            <div class="row">
                <div class="col-xxl-9 mx-auto py-4 mt-5 position-relative">
                    <Tabulator :items="items"></Tabulator>
                </div>
            </div>
        </section>
        <section class="container-fluid pl pr">
            <div class="row">
                <div class="col-xxl-9 mx-auto py-4 mt-5 text-center position-relative">
                    <div class="title-3 mb-4 lh-1" data-aos="fade-down"  data-aos-delay="50">Мы проработаем визуальный образ<br>Вашей компании</div>
                    <div class="title-4 mb-4" data-aos="fade-down"  data-aos-delay="50">Вам нужно только заполнить бриф</div>
                    <ButtonLime class="mb-5" data-aos="fade-up"  data-aos-delay="50">Заполнить бриф</ButtonLime>
                </div>
            </div>
        </section>
</template>

<script>
    import ButtonLime from "../components/ButtonLime";
    import Tabulator from "../components/Tabulator";
    export default {
        name: 'Design',
        components: {
            Tabulator,
            ButtonLime
        },
        data(){
            return {
                items: [
                    {
                        title: 'Логотип',
                        description: '4 шага к идеальному логотипу',
                        items:[
                            {
                                url:'./icons/strategy.png',
                                title: '',
                                description: 'Изучим специфику бизнеса, найдем изюминку и выделим ее',
                            },
                            {
                                url:'./icons/design.png',
                                title: '',
                                description: 'Разработаем логотип, сделаем наброски и создадим идеальный образец',
                            },
                            {
                                url:'./icons/programming.png',
                                title: '',
                                description: 'Сделаем итоговую отрисовку, пработаем логотип, добиваясь совершенных линий и безупречного сочитания цветов',
                            },
                            {
                                url:'./icons/support.png',
                                title: '',
                                description: 'Подготовим файлы исходников, собрав единый файл для дальнейшей работы',
                            }
                        ]
                    },
                    {
                        title: 'Фирменный стиль',
                        description: '4 шага к идеальному фирменному стилю',
                        items:[
                            {
                                url:'./icons/list.png',
                                title: '',
                                description: 'Выделим отличительные особенности компании, которые важно отразить в документе',
                            },
                            {
                                url:'./icons/prototype.png',
                                title: '',
                                description: 'Определим стратегию и единую концепцию, используя логотип и дополнительные элементы',
                            },
                            {
                                url:'./icons/programming.png',
                                title: '',
                                description: 'Продумаем правила использования всех визуальных элементов, цветовой схемы и шрифтов',
                            },
                            {
                                url:'./icons/test.png',
                                title: '',
                                description: 'Разработаем макеты корпоративной документации и сувенироной продукции',
                            }
                        ]
                    },
                    {
                        title: 'Брендбук',
                        description: '4 шага к идеальному брендбуку',
                        items:[
                            {
                                url:'./icons/list.png',
                                title: '',
                                description: 'Анализируем конкурентные преимущества бренда, выделяя главные отличия',
                            },
                            {
                                url:'./icons/prototype.png',
                                title: '',
                                description: 'Продумываем идеалогическую и графическую части брендбука',
                            },
                            {
                                url:'./icons/programming.png',
                                title: '',
                                description: 'Прорабатываем 4D модель для понимания сиильных и слабых сторон компании',
                            },
                            {
                                url:'./icons/test.png',
                                title: '',
                                description: 'Разработаем и собираем в единый документ все консттанты брендбука',
                            }
                        ]
                    }
                ],
            }
        }
    }
</script>
<style lang="scss">
    .design-container {
        h1 {
            font-size:12vw;
        }
        &:before {
            content: '';
            position: absolute;
            height: 100vh;
            width: 100vw;
            background: var(--lc-grey);
            top: -10%;
            left: -40%;
        }
    }
</style>
